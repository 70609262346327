import React, { Component } from 'react'
import { injectIntl } from "gatsby-plugin-intl"

import contactsStyle from './contacts.module.scss'

import Socials from '../social/socials.js'

class Contacts extends Component {
    render() {
        return (
            <div className={contactsStyle.contacts}>
                <div className={contactsStyle.contacts__content}>
                    <h3>Email</h3>
                    <a href="mailto:francesaravel@gmail.com" 
                    title="Email - Booking&Management"
                    className={contactsStyle.contacts__link}>francesaravel@gmail.com</a>
                </div>
                <div className={contactsStyle.contacts__content}>
                    <h3>Socials</h3>
                    <Socials
                        className={contactsStyle.contacts__socials}
                        email="francesaravel@gmail.com"
                        emailTitle="Email - Booking&Management"
                        ig="https://www.instagram.com/frances_aravel/"
                        igTitle=""
                        fb="https://www.facebook.com/FrancesAravel"
                        fbTitle=""
                    />
                </div>
            </div>
        
        )
    }
}

export default injectIntl(Contacts)
