import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade'

import Fly from "../../svg/fly.svg"
import SoundCloud from "../../svg/soundcloud-black.svg"
import Bandcamp from "../../svg/bandcamp-black.svg"
import Spotify from "../../svg/spotify-black.svg"
import Youtube from "../../svg/youtube-black.svg"

import style from './singleEP.module.scss'

import { useIntl } from "gatsby-plugin-intl"


const SingleEpContent = () => {
    const intl = useIntl()

    const epTitle = 'Fly';
    const SpotitfyUrl = 'https://open.spotify.com/track/6kZw6hBFuIpVTKwU9DHc3J?si=5tJRdWLgSdyI5-OfabYfsQ';
    const SoundCloudUrl = 'https://soundcloud.com/user-9994205/fly';
    const BandcampUrl = 'https://francesaravel.bandcamp.com/track/fly';
    const YouTubeUrl = 'https://www.youtube.com/watch?v=78pBeshTMVM';

    const data = useStaticQuery(graphql`
        query {
            imageFly: file(relativePath: { eq: "frances-aravel-fly-ep.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    `)
    return (
    <>
        <div className={style.singleEp__wrapper}>

            <div className={style.singleEp__imageContent}>

                <Fade top distance="5em">
                    <Img
                        fluid={data.imageFly.childImageSharp.fluid}
                        loading={`auto`}
                        draggable={false}
                        className={style.singleEp__imageWrapper}
                    />
                </Fade>

            </div>

            <div className={style.singleEp__content}>

                <Fade top distance={'1em'} delay={800}>
                    <div className={style.singleEp__mainSvg}>
                        <Fly />
                    </div>
                </Fade>
                
                <Fade top distance={'1em'} delay={900}>
                    <div>
                        <span>{intl.formatMessage({ id: "single.listen" })}</span>
                    </div>
                </Fade>

                <Fade top cascade delay={1000}>
                    <ul className={style.singleEp__list_link}>
                        <li>
                            <a href={SpotitfyUrl}
                                title={'Spotify - ' + epTitle + ' - Frances Aravel'}
                                target="BLANK">
                                <Spotify className={style.singleEp__icon} />
                            </a>
                        </li>
                        <li>
                            <a href={SoundCloudUrl}
                                title={'SoundCloud - ' + epTitle + ' - Frances Aravel'}
                                target="BLANK">
                                <SoundCloud className={style.singleEp__icon} />
                            </a>
                        </li>
                        
                        <li>
                            <a href={BandcampUrl}
                                title={'BandCamp - ' + epTitle + ' - Frances Aravel'}
                                target="BLANK">
                                <Bandcamp className={style.singleEp__icon} />
                            </a>
                        </li>
                    </ul>
                    <ul className={style.singleEp__list_link}>
                        <li>
                            <a href={YouTubeUrl}
                                title={'YouTube - ' + epTitle + ' - Frances Aravel'}
                                target="BLANK">
                                <Youtube className={style.singleEp__icon} />
                            </a>
                        </li>
                    </ul>
                </Fade>

            </div>

        </div>

    </>
    )
}
export default SingleEpContent
