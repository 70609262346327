import React, { Component } from 'react'
import { injectIntl } from "gatsby-plugin-intl"

import footerStyle from './siteFooter.module.scss'

import DanDanStudio from "../../svg/dandan-logo.svg";


class SiteFooter extends Component {
    render() {
        return (
            <footer className={footerStyle.siteFooter}>

                    <a
                        href="https://www.dandanstudio.it/"
                        title="Dan Dan Studio"
                >
                    <span>Design & Sviluppo</span>
                    <DanDanStudio />
                    </a>
                
            </footer>
        )
    }
}
export default injectIntl(SiteFooter)
