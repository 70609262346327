import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade'

import HeadPhones from "../../svg/headphones.svg"
import AmazonMusic from "../../svg/amazon-music.svg"
import AppleMusic from "../../svg/apple-music.svg"
import Deezer from "../../svg/deezer.svg"
import SoundCloud from "../../svg/soundcloud-black.svg"
import Bandcamp from "../../svg/bandcamp-black.svg"
import Spotify from "../../svg/spotify-black.svg"
import Youtube from "../../svg/youtube-black.svg"

import style from './singleEP.module.scss'

import { useIntl } from "gatsby-plugin-intl"

const SingleEpContent = () => {

    const epTitle = 'Gentle Night';
    const SpotitfyUrl = 'https://open.spotify.com/album/35rwlcq1up43LKmjWCC9S7?si=zkDj-u2TT_6pVBBeyvCO7A';
    const AppleMusicUrl = 'https://music.apple.com/it/album/gentle-night-single/1537727134';
    const SoundCloudUrl = 'https://soundcloud.com/user-9994205/gentle-night';
    const DeezerUrl = 'https://deezer.page.link/dLEn2Gv2t7GarT1Y7';
    const BandcampUrl = 'https://francesaravel.bandcamp.com/track/gentle-night';
    const AmazonMusicUrl = 'https://www.amazon.it/Gentle-Night-Frances-Aravel/dp/B08M4J6LYS/ref=sr_1_1?dchild=1&keywords=Frances+Aravel&qid=1604498625&s=dmusic&search-type=ss&sr=1-1';
    const YouTubeUrl = 'https://youtu.be/w9tpuB2dQII';
    
    const intl = useIntl()

    const data = useStaticQuery(graphql`
        query {
            imageGentle: file(relativePath: { eq: "frances-aravel-shining-gentle-night-ep.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    `)
    return (
    <>

        <div className={style.singleEp__wrapper}>

                <div className={style.singleEp__imageContent}>

                    <Fade top distance="5em">
                        <Img
                            fluid={data.imageGentle.childImageSharp.fluid}
                            loading={`auto`}
                            draggable={false}
                            className={style.singleEp__imageWrapper}
                        />
                    </Fade>

                </div>
                
                <div className={style.singleEp__content}>

                    <Fade top distance={'1em'} delay={800}>
                        <div className={style.singleEp__mainSvg}>
                            <HeadPhones />
                        </div>
                    </Fade>

                    <Fade top distance={'1em'} delay={900}>
                        <div>
                            <span>{intl.formatMessage({ id: "single.listen" })}</span>
                        </div>
                    </Fade>

                    <Fade top cascade delay={1000}>
                        
                        <ul className={style.singleEp__list_link}>
                            <li>
                                <a href={SpotitfyUrl}
                                    title={'Spotify - ' + epTitle + ' - Frances Aravel'}
                                    target="BLANK">
                                    <Spotify className={style.singleEp__icon} />
                                </a>
                            </li>
                            <li>
                                <a href={AppleMusicUrl}
                                    title={'Apple Music - ' + epTitle + ' - Frances Aravel'}
                                    target="BLANK">
                                    <AppleMusic className={style.singleEp__icon} />
                                </a>
                            </li>
                            <li>
                                <a href={DeezerUrl}
                                    title={'Deezer - ' + epTitle + ' - Frances Aravel'}
                                    target="BLANK">
                                    <Deezer className={style.singleEp__icon} />
                                </a>
                            </li>
                            <li>
                                <a href={SoundCloudUrl}
                                    title={'Soundcloud - ' + epTitle + ' - Frances Aravel'}
                                    target="BLANK">
                                    <SoundCloud className={style.singleEp__icon} />
                                </a>
                            </li>
                            
                            <li>
                                <a href={BandcampUrl}
                                    title={'BandCamp - ' + epTitle + ' - Frances Aravel'}
                                    target="BLANK">
                                    <Bandcamp className={style.singleEp__icon} />
                                </a>
                            </li>
                            <li>
                                <a href={AmazonMusicUrl}
                                    title={'Amazon Music - ' + epTitle + ' - Frances Aravel'}
                                    target="BLANK">
                                    <AmazonMusic className={style.singleEp__icon} />
                                </a>
                            </li>
                        </ul>
                        <ul className={style.singleEp__list_link}>
                            <li>
                                <a href={YouTubeUrl}
                                    title={'YouTube Video - ' + epTitle + ' - Frances Aravel'}
                                    target="BLANK">
                                    <Youtube className={style.singleEp__icon} />
                                </a>
                            </li>
                        </ul>
                    </Fade>
                </div>
            
            </div>

    </>
    )
}
export default SingleEpContent
