import React from "react"
import { Element } from 'react-scroll'
import { injectIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import SiteHeader from "../components/siteHeader/siteHeader"
import Layout from "../components/layout"
import Hero from "../components/hero/hero"
import SingleEp from "../components/singleEp/singleEp"
import Bio from "../components/bio/bio"
import Video from "../components/videoTemplate/videoTemplate"
import Contacts from "../components/contacts/contacts"
import SiteFooter from "../components/siteFooter/siteFooter"

const IndexPage = ({ intl }) => {
  const data = useStaticQuery(graphql`
      query {
          videoPoster: file(relativePath: { eq: "frances-aravel.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_noBase64
                  }
              }
          }
      }
  `)
  return (
    <>
        <SEO title={intl.formatMessage({ id: "title" })} />
        <SiteHeader />
          <Layout>          
          <Element name="top">
            <Hero />
          </Element>
          <Element name="ep">
            <SingleEp />
          </Element>
          <Element name="bio">
            <Bio />
          </Element>
          <Element name="video">
            <Video
              videoClasses="video-container--full-view"
              videoPoster={data.videoPoster.childImageSharp.fluid}
              videoPosterAlt="Frances Aravel - Shining Out Of The Dark (Official Video)"
              videoSrcURL="https://youtu.be/24Mv-yQJO3E"
              videoTitle="Frances Aravel - Shining Out Of The Dark (Official Video)"
            />
          </Element>
          <Element name="contacts">
            <Contacts />
          </Element>
        </Layout>
        <SiteFooter />
    </>
  )
};

export default injectIntl(IndexPage)
