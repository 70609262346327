import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from "gatsby-plugin-intl"

import Ig from "../../svg/instagram.svg";
import Email from "../../svg/email.svg";
import Fb from "../../svg/facebook.svg";

import socialsStyle from './socials.module.scss'

class Socials extends Component {
    render() {
        return (
        <ul className={socialsStyle.socials}>
            {this.props.email &&
                <li>
                    <a href={`mailto:` + this.props.email} title={this.props.emailTitle}>
                        <Email />
                    </a>
                </li>
            }
            {this.props.ig &&
                <li>
                    <a href={this.props.ig} title={this.props.igTitle}>
                        <Ig />
                    </a>
                </li>
            }
            {this.props.fb &&
                <li>
                    <a href={this.props.fb} title={this.props.fbTitle}>
                        <Fb />
                    </a>
                </li>
            }
        </ul>
        );
    }
}

Socials.propTypes = {
    email: PropTypes.string,
    emailTitle: PropTypes.string,
    ig: PropTypes.string,
    igTitle: PropTypes.string,
    fb: PropTypes.string,
    fbTitle: PropTypes.string,
}

Socials.defaultProps = {
    email: '',
    emailTitle: '',
    ig: '',
    igTitle: '',
    fb: '',
    fbTitle: '',
}

export default injectIntl(Socials)