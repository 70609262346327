import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom';

import bioStyle from './bio.module.scss'

const BioContent = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
            image: file(relativePath: { eq: "frances-aravel-bio.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    `)
    return (<>
        <div className={bioStyle.bio__imageWrapper}>
            <Img
                fluid={data.image.childImageSharp.fluid}
                fadeIn
                durationFadeIn={3000}
                loading={`auto`}
                backgroundColor={`#0e0e0e`}
                draggable={false} />
        </div>
        <div className={bioStyle.bio__cell}>
            <header className={bioStyle.bio__header}>
                <Fade 
                    delay={100}
                    duration={1000}>
                    <h3>Frances Aravel</h3>
                </Fade>
                <Zoom
                    delay={450}
                    duration={1000}>
                    <span><FormattedMessage id="bio.is" /></span>
                </Zoom>
                <Fade
                    delay={900}
                    duration={1000}>
                    <h3>Francesca Garavelli</h3>
                </Fade>
            </header>
            <Fade bottom cascade delay={1500} duration={3000}>
                <p><FormattedMessage id="bio.copy" /></p>
            </Fade>
        </div>
    </>)
}

const Bio = () => (
    <div className={bioStyle.bio}>
        <BioContent />
    </div>
)
export default injectIntl(Bio)
