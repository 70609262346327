import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import Fade from 'react-reveal/Fade';

import heroStyle from './hero.module.scss'

import LogoWhite from "../../svg/frances-aravel-logo-white.svg";

import Socials from '../social/socials.js'


const HeroContent = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
            image: file(relativePath: { eq: "frances-aravel.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    `)
    return (<>
        <Fade top distance="3em" delay={600} duration={3000}>
            <div className={heroStyle.hero__content}>
                <h1 className={heroStyle.hero__title}>
                    <LogoWhite className={heroStyle.hero__logo} />
                    <span>{data.site.siteMetadata.title}</span>
                </h1>
                <h2 className={heroStyle.hero__subtitle}>
                    <FormattedMessage id="hero_role" />
                </h2>
                <Socials 
                    email="francesaravel@gmail.com"
                    emailTitle="Email - Booking&Management"
                    ig="https://www.instagram.com/frances_aravel/"
                    igTitle=""
                    fb="https://www.facebook.com/FrancesAravel"
                    fbTitle=""
                />
            </div>
        </Fade>
        <div className={heroStyle.hero__imageWrapper}>
            <Img
                fluid={data.image.childImageSharp.fluid}
                fadeIn
                durationFadeIn={3000}
                loading={`auto`}
                backgroundColor={`#0e0e0e`}
                draggable={false}/>
        </div>
    </>)
}

const Hero = () => (
    <div className={heroStyle.hero}>
        <div className={heroStyle.hero__container}>
            <HeroContent />
        </div>
    </div>
)
export default injectIntl(Hero)

