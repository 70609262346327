import React from "react"

import SingleEpShiningEp from "./singleEp-shining-ep"
import SingleEpGentle from "./singleEp-gentle-night"
import SingleEpShining from "./singleEp-shining"
import SingleEpFly from "./singleEp-fly"

import style from './singleEP.module.scss'

const SingleEpContent = () => {
    return (
    <>
        <div className={style.singleEp}>
            <SingleEpShiningEp />
            <SingleEpGentle />
            <SingleEpShining />
            <SingleEpFly />
        </div>
    </>
    )
}
export default SingleEpContent
