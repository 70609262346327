import React, { Component } from 'react'
import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"
import { Link } from "react-scroll";

import headerStyle from './siteHeader.module.scss'

import LogoWhite from "../../svg/frances-aravel-logo-white.svg";

class SiteHeader extends Component {
  state = {
    isOpen: false
  };

  handleMenu = () => {
    this.setState(state => ({
      isOpen: !state.isOpen,
    }));
  };

  render() {

    return(
      <header className={
          headerStyle.siteHedaer 
          + ' ' + 
          (this.state.isOpen ? headerStyle.siteHedaer_open : '')
        }>
        <div className={headerStyle.siteHedaer__container}>
          <Link
            onClick={this.handleMenu} 
            to="top"
            spy={true}
            smooth={true}
            offset={-10}
            duration={600}
            title="top"
            className={headerStyle.siteHedaer__logo}>
            <LogoWhite />
          </Link>
          <nav className={headerStyle.siteHedaer__nav}>
            <ul className={headerStyle.siteHedaer__list}>
              <li>
                <Link
                  onClick={this.handleMenu} 
                  activeClass="link--active"
                  to="ep"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={600}
                  title="Ep"
                  className="link link--nav"
                >
                  <span>Nuovo EP</span>
                </Link>
              </li>
              <li>
                <Link
                  onClick={this.handleMenu}
                  activeClass="link--active"
                  to="bio"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={600}
                  title="Biografia"
                  className="link link--nav"
                >
                  <span>Biografia</span>
                </Link>
              </li>
              <li>
                <Link
                  onClick={this.handleMenu}
                  activeClass="link--active"
                  to="video"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={600}
                  title="Video"
                  className="link link--nav"
                >
                  <span>Video</span>
                </Link>
              </li>
              <li>
                <Link
                  onClick={this.handleMenu}
                  activeClass="link--active"
                  to="contacts"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={600}
                  title="Contatti"
                  className="link link--nav"
                >
                  <span>Contatti</span>
                </Link>
              </li>
            </ul>
          </nav>

          <button 
            onClick={this.handleMenu} 
            type="button"
            className={headerStyle.siteHedaer__hamburger}>
            <span></span>
          </button>
        
        </div>
      </header>

    )
  }
}

SiteHeader.propTypes = {
  siteTitle: PropTypes.string,
}

SiteHeader.defaultProps = {
  siteTitle: '',
}

export default injectIntl(SiteHeader)
