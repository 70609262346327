import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import classNames from 'classnames'
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl"

import Play from "../../svg/play.svg"
import Pause from "../../svg/pause.svg"

class Video extends Component {
    state = {
        isActive: false,
        playingVideo: false
    };

    handlePlay = () => {
        this.setState(state => ({ 
            isActive: !state.isActive,
            playingVideo: !state.playingVideo
        }));
    };

    render() {

        const videoStatusClass = classNames({
            active: this.state.isActive
        });

        return (
            <div className={this.props.videoClasses + ' video-container ' + videoStatusClass}>
                
                {this.props.videoPoster &&
                    <div className="video-container__poster">
                        <Img
                            fluid={this.props.videoPoster}
                            fadeIn
                            durationFadeIn={3000}
                            loading={`auto`}
                            backgroundColor={`#0e0e0e`}
                            draggable={false}
                            alt={this.props.videoPosterAlt}
                        />
                    </div>
                }

                <ReactPlayer 
                    url={this.props.videoSrcURL}
                    pip={true}
                    ref="vidRef"
                    width="100%"
                    height="100%"
                    controls={false}
                    playing={this.state.playingVideo}
                    className='video-container__media-player'
                ></ReactPlayer>
                
                <button onClick={this.handlePlay}
                    type="button"
                    className="video-container__btn">
                    <Play className="video-container__play"/>
                    <Pause className="video-container__pause"/>
                </button>

            </div>
        );
    }
}

Video.propTypes = {
    videoClasses: PropTypes.string,
    videoSrcURL: PropTypes.string,
    videoPoster: PropTypes.object,
    videoPosterAlt: PropTypes.string,
}

Video.defaultProps = {
    videoClasses: '',
    videoSrcURL: '',
    videoPoster: [],
    videoPosterAlt: '',
}

export default injectIntl(Video)